import React from 'react';

import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';

import {
  data as docData,
  DSTRAI,
} from '@lba-dev/package.local-globals/docTypes';

import ReportList from '../../ReportList/ReportList';
import CollapsedCard from '../../Utils/CollapsedCard';
import FileDisplayer from '../../Utils/FileDisplayer';
import DropboxUpload from '../../DropboxUpload';
import ImageList from '../ImageList';
import { fetchDropboxFiles } from '../../../actions/artisan';
import { withAPISubscription } from '../../../hoc';
import { checkDocument } from '../../../utils/function';
import api from '../../../api';
import notifSystem from '../../../notifSystem';
import { displayDocument } from '../../../utils/windowOpenFunction';

const styles = {
  iframe: {
    height: '800px'
  },
  img: {
    maxWidth: '40em',
    width: '100%'
  },
  container: {
    textAlign: 'center'
  },
  generContrat: {
    marginRight: 10,
  }
};

const previewContrat = (data) => {
  api.all('pdfhtmlform').post({
    method: 'contratsst',
    info: { type: 'contratsst', noCondition: true },
    data: { data }
  }).then(res => {
    displayDocument(res.body().data().replace(/,/g, ''));
  }).catch(() => {
    notifSystem.error('Erreur', 'Le pdf n\'a pas pu être généré');
  });
};

const DocumentsList = ({
  classes,
  data: files,
  displayFile = true,
  fileToDisplay = files.length && files[files.length - 1],
  type,
  underscoreId,
  id,
  artisan,
  regex = type,
  reload,
  collection,
  buttonDisabled = false,
  historyFilter = {
    history: JSON.stringify({
      text: {
        $regex: regex
      },
    }),
  },
  ...props
}) => {
  const buttonName = (docData.find(e => e._id === type) || {}).name;
  const uploadButton = (
    <DropboxUpload
      buttonName={buttonName || 'Upload'}
      type={type}
      id={id}
      length={files.length + 1}
      getFile={reload}
      color={checkDocument(files, { type }, {})}
      path={collection}
      underscoreId={underscoreId}
      disabled={buttonDisabled}
    />
  );

  return (
    <div>
      {displayFile && !!fileToDisplay && (
        <FileDisplayer
          classes={classes}
          content={fileToDisplay.url}
          name={fileToDisplay.name}
          fileOpenState={true}
        />
      )}
      <CollapsedCard
        elevation={0}
        title="Fichiers"
        defaultOpen={files.length < 5}
      >
        <ImageList
          {...props}
          docType={buttonName}
          id={id}
          getFile={reload}
          data={files}
        />
      </CollapsedCard>
      <CollapsedCard
        elevation={0}
        title="Historique"
        action={type === DSTRAI ? (
          <React.Fragment>
            <Button
              className={classes.generContrat}
              variant="contained"
              color="primary"
              children="Générer le contrat"
              onClick={() => previewContrat(artisan)}
            />
            {uploadButton}
          </React.Fragment>
        ) : (
          uploadButton
        )}
      >
        <ReportList
          elevation={0}
          filter={historyFilter}
          categories={[3, 7]}
          search="artisanId"
          searchObjectId={underscoreId}
        />
      </CollapsedCard>
    </div>
  );
};

const fetch = (a, { files, underscoreId, fetchFiles, collection }) =>
  files ||
  (typeof fetchFiles === 'function'
    ? fetchFiles(() => fetchDropboxFiles(underscoreId, collection))
    : fetchDropboxFiles(underscoreId, collection)
  );
const actions = [
  {
    action: ({ underscoreId, collection }) =>
      `update_${collection}_${underscoreId}`,
    func: (fetch, { type }, typeFromSocket) =>
      type === typeFromSocket && fetch(),
  }
];

const shouldReload = (props, prevProps) =>
  props.filesUpdated !== prevProps.filesUpdated ||
  props.files !== prevProps.files ||
  props.type !== prevProps.type ||
  props.id !== prevProps.id;

export default withAPISubscription(
  fetch,
  actions,
  { shouldReload }
)(withStyles(DocumentsList, styles));
