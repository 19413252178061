import React, { Component } from 'react';
import { is, fromJS } from 'immutable';
import mapboxgl from 'mapbox-gl';
import api from '../../api';
import 'mapbox-gl/dist/mapbox-gl.css';
import { layers, source, eventCtrl, map } from './Objects';
import notifSystem from '../../notifSystem';
import { moment } from '@lba-dev/package.local-globals/moment';
import keys from '../../constants/keys';
import {
  keySearch
} from '@lba-dev/package.local-globals/searchProsp';
import loadImages from '../../utils/loadImages';

mapboxgl.accessToken = keys.mapbox;

export default class Map extends Component {
  constructor() {
    super();
    this.timeout = null;
    this.crefs = {
      map: null,
    };
    this.layers = [];
    this.map = null;
    this.marker = null;
  }

  /*
   **
   ** Create Gmap Marker
   ** options: marker creation option
   ** color: name of image in front/public
   ** click: on marker click listener
   **
   */
  createMarker = (
    {
      map,
      className = '',
      position = [2.0474905, 46.1445302],
      value,
      title = 'client',
      createElement,
    },
    color,
    click
  ) => {
    const el = document.createElement('div');
    el.key = value;
    el.title = title;
    if (createElement) {
      createElement(el);
    } else {
      const image = loadImages(color);
      console.log(image, color);
      el.style.backgroundImage = `url(${image})`;
      el.style.backgroundSize = 'contain';
    }
    el.style.width = '40px';
    el.style.height = '40px';
    el.style.backgroundRepeat = 'no-repeat';

    el.className = className;
    if (click && value) {
      el.addEventListener('click', click);
    }
    return new mapboxgl.Marker(el)
      .setLngLat(position)
      .addTo(map);
  };

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.crefs.map,
      style: 'mapbox://styles/mapbox/streets-v9?optimize=true',
      zoom: 5.5,
      center: [2.0474905, 46.1445302],
    });
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.on('wheel', eventCtrl);
    this.marker = this.createMarker({ map: this.map }, this.props.marker.color);
    this.updatePlace(this.props.marker.latlng);
    if (this.props.RecherchePage) {
      this.map.on('moveend', this.setCenterEvent);
    }
    if (this.props.markerList) {
      this.updateMarkers(this.props.markerList, false);
      if (this.props.target) {
        this.displayRoute( this.props.markerList.find(
          elem => elem.getIn(['obj', 'value']) === this.props.target
        ));
      }
      if (this.props.cities) {
        this.updateCircleCities(this.props.cities);
      }
    }

  }

  shouldComponentUpdate(props) {
    return (
      this.props.moveMarker !== props.moveMarker ||
      this.props.marker.latlng !== props.marker.latlng ||
      !is(this.props.markerList, props.markerList) ||
      this.props.target !== props.target ||
      props.cities !== this.props.cities
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.moveMarker !== prevProps.moveMarker) {
      this.moveTo(this.props.moveMarker);
    }
    if (this.props.marker.latlng !== prevProps.marker.latlng) {
      this.updatePlace(this.props.marker.latlng);
    }
    if (!is(this.props.markerList, prevProps.markerList)) {
      this.updateMarkers(this.props.markerList, true);
      if (this.props.target) {
        this.displayRoute(this.props.markerList.find(
          elem => elem.getIn(['obj', 'value']) === this.props.target
        ));
      }
    }
    else if (this.props.target !== prevProps.target) {
      this.displayRoute(this.props.markerList.find(
        elem => elem.getIn(['obj', 'value']) === this.props.target
      ));
    }
    if (prevProps.cities !== this.props.cities) {
      this.updateCircleCities(this.props.cities);
    }
  }

  deleteLayersAndSource = () => {
    if (this.layers) {
      this.layers.forEach(e => {
        const mpLayer = this.map.getLayer(e);
        if (mpLayer) {
          this.map.removeLayer(e);
        }
      });
      const mpSource = this.map.getSource('markers');
      if (mpSource) {
        this.map.removeSource('markers');
      }
      this.layers = [];
    }
  };

  setCenterEvent = () => {
    const map = this.map;
    const { moveInMaps, marker } = this.props;
    if (moveInMaps) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      let center = map.getCenter();
      if (!marker.latlng ||
        (marker.latlng[0] !== center.lat
          || marker.latlng[1] !== center.lng)) {
        this.timeout = window.setTimeout(() => {
          center = map.getCenter();
          this.props.updateData(
            'mapAddress',
            fromJS({
              location: {
                coordinates: [center.lng, center.lat],
              },
            })
          );
        }, 3000);
      }
    }
  };

  updateMarkers(markers, deleteRef) {
    if (deleteRef) {
      [...document.getElementsByClassName('marker')].forEach(e => e.remove());
    }
    markers
      .toJS()
      .filter(e => !e.hide)
      .forEach((elem, index) => {
        this.createMarker(
          {
            map: this.map,
            position: [elem.obj.coordinates.lng, elem.obj.coordinates.lat],
            title: elem.obj.title,
            value: elem.obj.value,
            className: 'marker',
            createElement: elem.createElement
          },
          elem.color,
          this.props.RecherchePage
            ? () => elem.markerAction(index)
            : () => {
              if (!this.props.devisPage && this.props.markerAction) {
                this.props.markerAction(fromJS(elem));
              }
            }
        );
      });
  }

  updateCircleCities(cities) {
    this.deleteLayersAndSource();
    const newSources = [],
      newLayers = [];
    if (this.map._loaded) {
      cities.forEach((city, i) => {
        newSources.push(source({ city, index: i }));
        newLayers.push(layers({ city, index: i }));
      });
      this.map.addSource('markers', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: newSources,
        },
      });
      newLayers.forEach(e => this.map.addLayer(e));
      this.layers = newLayers.map(e => e.id);
    }
    return null;
  }

  displayRoute(origin) {
    if (!(this.props.moveMarker && this.props.moveMarker.type === keySearch)
      && origin) {
      const destination = this.marker.getLngLat();
      api.map
        .custom('direction')
        .get({
          query: JSON.stringify({
            destination,
            origin: origin.getIn(['obj', 'coordinates']),
            travelMode: 'driving',
          }),
        })
        .then(response => {
          response = response.body().data();
          if (response.routes[0]) {
            const route = response.routes[0];
            if (this.props.setTargetInfo) {
              const duration = moment.duration(route.duration, 'seconds');
              const obj = {
                distance: `${Math.round(route.distance / 1000)} km`,
                duration: `${duration.hours()} h ${duration.minutes()} min`,
              };
              this.props.setTargetInfo(obj);
            }
            const geojson = {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: route.geometry.coordinates,
              },
            };
            if (!this.map.getSource('route')) {
              this.map.addLayer(map({ geojson }));
            }
            this.map.getSource('route').setData(geojson);
            this.map.fitBounds(
              [
                [destination.lng, destination.lat],
                [
                  origin.getIn(['obj', 'coordinates', 'lng']),
                  origin.getIn(['obj', 'coordinates', 'lat']),
                ],
              ],
              { padding: 50 }
            );
          }
        })
        .catch(e => notifSystem.error(e.name, e.message));
    }
  }

  updatePlace(latlng) {
    if (!latlng) {
      if (!this.props.RecherchePage || !this.props.moveInMaps) {
        this.map.setZoom(6);
      }
      this.map.setCenter({ lat: 46.1445302, lng: 2.0474905 });
      if (this.map.getSource('route')) {
        this.map.removeSource('route');
      }
      return this.marker.setDraggable(false);
    }
    if (!this.props.RecherchePage || !this.props.moveInMaps) {
      this.map.setZoom(this.props.zoom || 13);
    }
    let loc = { lat: latlng[0], lng: latlng[1] };
    this.map.setCenter(loc);
    this.marker.setLngLat(loc);
    this.marker.setDraggable(true);
  }

  moveTo = ({ coordinates, type }) => {
    if (coordinates && type === keySearch) {
      this.map.setZoom(16);
      const loc = { lat: coordinates[0], lng: coordinates[1] };
      this.map.panTo(loc);
      this.marker.setLngLat(loc);
    }
  };

  render() {
    const { width, height } = this.props;

    return (
      <div
        ref={el => {
          this.crefs.map = el;
        }}
        style={{
          width: width ? width : '100%',
          height: height ? height : '100%',
        }}
      ></div>
    );
  }
}
