import React, { useEffect, useState } from 'react';
import InterActions from './InterActions';
import InterMenu from './InterMenu';
import PropositionActions from '../Proposition/PropositionActions';
import creditAideActions from '../Recouvrement/creditAideActions';
import { List as listData } from '../../../utils/List/ListeInterventions';
import { callBackFunction } from '../../Utils/ListFunctions';
import RibMenu from './RibMenu';
import RecouvrementResolutionsAVerifMenu from
  '../Recouvrement/RecouvrementResolutionsAVerifMenu';
import AchatMenu from '../Achat/AchatMenu';
import AchatActions from '../Achat/AchatActions';
import AdemarcherActions from './AdemarcherActions';
import collections from '../../../constants/collections';
import ListLogic from '../ListLogic';
import { useSelector } from 'react-redux';

const menus = {
  default: InterMenu,
  rib: RibMenu,
  RecouvrementResolutionsAVerifMenu: RecouvrementResolutionsAVerifMenu,
  AchatMenu: AchatMenu,
};

const actions = {
  default: InterActions,
  PropositionActions: PropositionActions,
  creditAideActions: creditAideActions,
  AchatActions: AchatActions,
  AdemarcherActions: AdemarcherActions,
};

const functionType = (grandCompte) => [
  {
    type: 'categorie',
    callback: callBackFunction['categorie'],
  },
  {
    type: 'substatus',
    callback: callBackFunction['paid'],
  },
  {
    type: 'iStatus',
    callback: callBackFunction['iStatus'],
  },
  {
    type: 'equipeAreasClient',
    callback: callBackFunction['equipeAreasClient'],
  },
  {
    type: 'appel',
    callback: callBackFunction['appel'],
    fieldsToReset: ['communcations.duration'],
  },
  {
    type: 'service',
    callback: callBackFunction['service'],
  },
  ...(grandCompte ? [{
    type: 'billing.grandCompte',
    callback: (value) =>
      callBackFunction['grandCompte'](value, grandCompte.toJS()),
  }] : []
  )
];

const InterList = (props) => {
  const {
    name = collections.interventions.capitalize(),
    listen = collections.interventions,
    action,
    handlerFilterByColumns,
    menu,
    sort,
    filterName,
    count,
    filter: filterProps,
    list
  } = props;

  const [filter, setFilter] = useState(filterProps);
  const [display] = useState(count || 25);

  const { user, grandCompte } = useSelector(
    ({ users, userId, grandCompte }) => ({
      user: users.find(e => e._id === userId),
      grandCompte
    })
  );

  const newListData = (list || listData({
    user: user,
    service: props.service,
  }).default.dataFormat);

  useEffect(() => {
    setFilter(filterProps);
  }, [filterProps]);

  if (filterName !== 'default' && Object.keys(filter).length === 0) {
    return null;
  }

  const func = functionType(grandCompte);

  return (
    <React.Fragment>
      <ListLogic
        {...newListData}
        functionType={func}
        project={[]}
        name={name}
        actions={actions[action || 'default']}
        collection={listen}
        filteredByColumn
        handlerFilterByColumns={handlerFilterByColumns}
        listen={listen}
        filter={filter}
        count={display}
        sort={sort}
        menu={menus[menu || 'default']}
        small
      />
    </React.Fragment>
  );
};

export default InterList;

