import React, { Component } from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { withStyles } from 'tss-react/mui';
import { showFilter } from '../../../utils/function';
import Input from '@mui/material/Input';
import styles from './styles';
import { withMediaQuery } from '../../../hoc';

class TableHeader extends Component {

  shouldComponentUpdate(nextProps) {
    return (JSON.stringify(nextProps.keys) !==
      JSON.stringify(this.props.keys) ||
      JSON.stringify(nextProps.table) !== JSON.stringify(this.props.table) ||
      nextProps.isDown.md !== this.props.isDown.md);
  }

  genFilterField(elem) {
    elem = typeof elem === 'object' ? JSON.stringify(elem) : elem;
    return (<TableCell key={elem} className={this.props.classes.input}>
      <Input
        fullWidth
        onKeyPress={this.props.updateFilter}
        name={elem}
      />
    </TableCell>);
  }

  render() {
    const {
      table,
      keys,
      setFilter,
      classes,
      handlerFilterByColumns,
      name,
      filteredByColumn
    } = this.props;
    const isMobile = this.props.isDown.md;
    return (
      <TableHead>
        <TableRow className={classes.ArrowHeader}>
          {Object.keys(table).map(elem => (
            <TableCell
              variant="head"
              className={classes.masterDiv}
              padding='checkbox'
              key={elem}
            >
              {elem}
              { filteredByColumn &&
                showFilter(elem, classes, handlerFilterByColumns, name)
              }
            </TableCell>
          ))}
        </TableRow>
        {
          setFilter && !isMobile && <TableRow>
            {keys.map(elem => (
              this.genFilterField(elem)
            ))}
          </TableRow>
        }
      </TableHead>
    );
  }
}

export default withMediaQuery()(withStyles(TableHeader, styles));
