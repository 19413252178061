import React, { Component } from 'react';
import { compose } from 'redux';
import { Route, Routes } from 'react-router-dom';

import { logout } from '../actions/auth';
import store from '../store';
import { withStyles } from 'tss-react/mui';
import TrackingUsers from '../components/TrackingUsers';
import Notifications from '../components/Notifications';
import DialogManager from './DialogManager';
import { getLStorageData } from '../utils/localStorgeFunctions';
import ws from '../ws';
import { checkMessagesToAnswer } from '../actions/general';
import LoadingManager from './LoadingManager';
import notifSystem from '../notifSystem';
import Dash from '../components/Dashboard/Dash';
import withRouter from '../hoc/withRouter';
import { connect } from 'react-redux';
import ResetPassword from '../components/ResetPassword';
import Login from './Login';
import internalRouter from '../components/Dashboard/InternalRouter';

const mapStateToProps = ({ auth }) => ({ auth });

const styles = (theme) => ({
  elem: {
    position: 'absolute',
    top: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: 48,
    },
    [theme.breakpoints.up('sm')]: {
      top: 64,
    },
    bottom: 0,
    transition: 'left 0.3s',
    overflowY: 'overlay',
    overflowX: 'hidden',
  },
  sidebar: {
    width: 250,
    backgroundColor: '#333',
    zIndex: 2,
  },
  main: {
    backgroundColor: 'lightgray',
  },
  fullHeight: {
    height: '100%',
  },
});

class Dashboard extends Component {
  state = {
    user: null,
  };

  componentDidMount() {
    try {
      const { navigate, location } = this.props;
      navigate(location.pathname, {
        replace: true,
        state: {
          state: '',
          search: location.search,
        }
      });
    } catch (e) {
      return false;
    }
    if (Object.keys(ws).length && ws.socket) {
      ws.on('customMessage', this.wsUpdate);
      ws.on('forceDisconnect', (data) => {
        const { userId } = store.getState();
        if (userId === data.id) {
          notifSystem.error('Erreur', 'Connexion perdue');
          store.dispatch(logout(ws));
        }
      });
    }
  }

  componentWillUnmount() {
    ws.removeEventListener('customMessage', this.wsUpdate);
  }

  wsUpdate = (data) => {
    const { auth = {}, location = {} } = this.props;
    const { decodedToken = {} } = auth;
    if (
      data.type === 'toAnswer' &&
      decodedToken._id === data.userId &&
      !((location || {}).pathname || '').includes('openSMS') &&
      window.name !== 'chat'
    ) {
      checkMessagesToAnswer({ ...data });
    }
  };

  static getDerivedStateFromProps() {
    getLStorageData('Inter');
    getLStorageData('Devis');
    return null;
  }

  render() {
    const { classes, auth } = this.props;
    const connected = auth && auth.token;
    const isChat = window.name === 'chat';
    return (
      <React.Fragment>
        <div className={classes.fullHeight}>
          <Routes>
            {!isChat && !connected && [
              <Route
                key={0}
                path="/resetPassword/:id"
                element={<ResetPassword />}
              />,
              <Route
                key={1}
                path="*"
                element={<Login />}
              />
            ]}
            {(isChat || connected) &&
              <Route element={<Dash auth={auth} />}>
                {internalRouter(auth.decodedToken, isChat)}
              </Route>}
          </Routes>
          <Notifications />
        </div>
        <DialogManager />
        <LoadingManager />
        {connected && <TrackingUsers auth={auth} />}
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps)
)(withStyles(Dashboard, styles));
