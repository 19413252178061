export default {
  Interventions: {
    OS: {
      fields: 'id',
    },
    Telepro: {
      fields: 'login.ajout'
    },
    Envoi: {
      fields: 'login.envoi'
    },
    'Prochaine relance': {
      fields: 'date.reminder',
    },
    Ajout: {
      fields: 'date.ajout',
    },
    Vérification: {
      fields: 'date.ajout',
    },
    'Cat.': {
      fields: 'categorie',
    },
    Client: {
      fields: 'client.firstname',
    },
    Ville: {
      fields: 'client.address.zipcode',
    },
    Prevu: {
      fields: 'date.intervention',
    },
    Prix: {
      fields: 'announcedPrice',
    },
    iStatus: {
      fields: 'status',
    },
    Points: {
      fields: 'points',
    },
    rSST: {
      fields: 'paid',
    },
    Appel: {
      fields: 'communcations.duration',
    },
    Temps: {
      fields: 'date.recouvrement',
    },
  },
  Devis: {
    OS: {
      fields: 'id',
    },
    Telepro: {
      fields: 'login.ajout'
    },
    Ajout: {
      fields: 'date.ajout',
    },
    'Cat.': {
      fields: 'categorie',
    },
    Client: {
      fields: 'client.firstname',
    },
    Ville: {
      fields: 'client.address.zipcode',
    },
    Prix: {
      fields: 'finalPrice',
    },
    Status: {
      fields: 'status',
    },
    Email: {
      fields: 'postmarkmail',
    },
  },
  Demandes: {
    Client: {
      fields: 'client.firstname',
    },
    Telepro: {
      fields: 'login.ajout'
    },
    Date: {
      fields: 'date.ajout',
    },
    Choix: {
      fields: 'choice',
    },
    'Code postal': {
      fields: 'client.zipCode',
    },
    Téléphone: {
      fields: 'client.tel1',
    },
    Source: {
      fields: 'source',
    },
    Statut: {
      fields: 'status',
    },
  },
  Artisans: {
    ID: {
      fields: 'id',
    },
    Création: {
      fields: 'date.ajout',
    },
    'Prochaine relance': {
      fields: 'date.reminder',
    },
    'Prise en charge': {
      fields: 'login.debrief',
    },
    'Nom de société': {
      fields: 'companyName',
    },
    Ville: {
      fields: 'address.zipcode',
    },
    Dossier: {
      fields: '_dossier',
    },
    Payées: {
      fields: 'interPayer',
    },
    Vérifiées: {
      fields: 'verifiedInterventions',
    },
    Reglées: {
      fields: 'interReglesVerif',
    },
    Statut: {
      fields: 'status',
    },
    'Sous-statut': {
      fields: 'subStatus',
    },
    Rémunération: {
      fields: 'remuneration.pourcentage.labor',
    },
    '1ère Vérif': {
      fields: 'date.firstVerif',
    },
  },
  RecouvrementSST: {
    ID: {
      fields: 'id',
    },
  },
  recouvrements: {
    OS: {
      fields: 'id',
    },
  },
  SavInterventions: {
    OS: {
      fields: 'id',
    },
    Telepro: {
      fields: 'login.ajout'
    },
    'Cat.': {
      fields: 'categorie',
    },
    Ajout: {
      fields: 'date.ajout',
    },
    Client: {
      fields: 'client.firstname',
    },
    Ville: {
      fields: 'client.address.zipcode',
    },
    Prevu: {
      fields: 'date.intervention',
    },
    Prix: {
      fields: 'announcedPrice',
    },
    Status: {
      fields: 'status',
    },
    rSST: {
      fields: 'paid',
    },
  },
  Candidats: {
    id: {
      fields: 'id',
    },
    'Date de candidature': {
      fields: 'date.creation',
    },
    'Source candidature': {
      fields: 'source',
    },
    'Nom de société': {
      fields: 'companyName',
    },
    Nom: {
      fields: ['name', 'firstname'],
    },
    'Code postal': {
      fields: 'address.zipcode',
    },
  },
};


