import React, { Component } from 'react';

import Map from '../Maps/Map';
import CustumHeader from '../Maps/CustumHeader';
import { fromJS, List } from 'immutable';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { withStyles } from 'tss-react/mui';
import { CircularProgress } from '@mui/material';

const styles = {
  root: {
    height: window.innerHeight - 90,
    maxHeight: window.innerHeight - 90,
    width: '100%',
    marginTop: 0,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: window.innerHeight - 90,
  }
};

class ProspMap extends Component {
  constructor(props){
    super(props);
    this.state = {
      address: '',
      marker: {
        color: 'greyPin.svg'
      }
    };
  }

  updateAddress = (place, cb = null) => {
    if (!(place || {}).geometry) {
      return false;
    }
    const update = {
      marker: {
        ...this.state.marker,
        latlng: [place.geometry.coordinates[1],
          place.geometry.coordinates[0]]
      } };
    this.setState(update, () => {
      this.props.updateData(this.props.path, fromJS({
        location: {
          coordinates:
            [place.geometry.coordinates[0],
              place.geometry.coordinates[1]]
        }
      }), cb);
    });
  }

  setAddress = (address) => this.setState({ address })

  render() {
    const { address } = this.state;
    const {
      markerList,
      moveMarker,
      updateData,
      target,
      moveInMaps,
      setTargetInfo,
      originalAddress,
      classes,
      isInterSearch,
      mapIsLoading
    } = this.props;
    const marker = !this.state.marker.latlng ? {
      ...this.state.marker,
      ...( originalAddress ? {
        latlng: [
          originalAddress.getIn(['location', 'coordinates', '1']),
          originalAddress.getIn(['location', 'coordinates', '0'])
        ]
      } : {})
    } : this.state.marker;
    return (
      <Card elevation={0}>
        <CardContent style={{ padding: 1 }}>
          {<CustumHeader
            isInterSearch={isInterSearch}
            updateAddress={this.updateAddress}
            address={address}
            setAddress={this.setAddress}
            updateData={updateData}
          />
          }
          <Grid
            className={classes.root}
          >
            {
              mapIsLoading && <div style={styles.loading}>
                <CircularProgress size={70} color="secondary" />
              </div>
            }
            <Map
              RecherchePage
              markerList={markerList || new List()}
              marker={marker}
              moveMarker={moveMarker}
              target={target}
              setTargetInfo={setTargetInfo}
              updateData={updateData}
              moveInMaps={moveInMaps}
            />
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(ProspMap, styles);
