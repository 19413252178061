import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import Fab from '@mui/material/Fab';
import Money from '../../../utils/Money';
import { categories } from '@lba-dev/package.local-globals/getDataById';
import api from '../../../api';
import Map from '../../Maps/Map';
import { List } from 'immutable';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { withStyles } from 'tss-react/mui';
import { CateogriesFiltres } from '../../MoteurDeRecherche';
import ShowPrice from './ShowPrice';
import SelectUser from './SelectUser';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';

const styles = {
  main: {
    position: 'relative',
  },
  button: {
    position: 'absolute',
    zIndex: 100,
  },
  hide: { height: '100%', maxWidth: '2%' },
  display: { height: '100%', margin: '0 0 0 -10px', maxWidth: '17%' },
  filter: {
    boxShadow: `4px 3px 14px ${'#000000'}`,
    borderRadius: 8,
    zIndex: 1,
    margin: '0 0 0 -10px',
    overflowY: 'auto',
    backgroundColor: 'white',
  }
};

const mapStateToProps = ({ users }) => ({
  users
});

const generateMarkerList = (interventions, showPrice) =>
  interventions.map((i) => ({
    createElement: (el) => {
      ReactDOM.render(
        React.createElement(() => (
          <Grid container>
            <Grid item>
              <Icon
                onClick={() => window.open(`/intervention/${i.id}`)}
                style={{ color: categories[i.categorie].color[500] }}
                children="pin_drop"
              />
            </Grid>
            {showPrice
              ? <Grid item
                style={{
                  backgroundColor: '#fff',
                  minWidth: 70,
                  borderRadius: 20
                }}
              >
                <Typography children={Money.toString(i.finalPrice, true)} />
              </Grid>
              : ''}
          </Grid>
        )),
        el
      );
    },
    color: 'bluePin.svg',
    dis: 1.4102503239555897,
    obj: {
      coordinates: {
        lat: i.client.address.location
          ? i.client.address.location.coordinates[1]
          : 0,
        lng: i.client.address.location
          ? i.client.address.location.coordinates[0]
          : 0,
      },
      tel1: i.client.tel1,
      title: Money.toString(i.finalPrice > 0 ? i.finalPrice : i.announcedPrice),
      value: i._id,
    },
    inter: i
  }));

const filterUsers = (users, data) => {
  const ids = data.map(e => e.inter.login.ajout);
  return users.filter(e => ids.includes(e._id));
};

class MapList extends Component {
  state = {
    data: [],
    showFilters: false,
    selectedCat: Object.values(categories).map((e) => e.name),
    selectedUsers: [],
    showPrice: true,
    allUsers: true,
    users: this.props.users.filter(e => e.act),
  };

  getData = (filter, collection, showPrice) =>
    api[collection].getAll({ query: JSON.stringify(filter) }).then((e) =>
      generateMarkerList(
        e.body().map((d) => d.data()),
        showPrice
      )
    );

  getCatId = (name) =>
    Object.values(categories).find((e) => e.name === name)._id;

  componentDidMount() {
    const { filter, collection } = this.props;
    const { showPrice } = this.state;
    return this.getData(filter, collection, showPrice).then((data) => {
      const newUsers = filterUsers(this.state.users, data);
      this.setState({
        data,
        users: newUsers,
        selectedUsers: newUsers.map(e => e._id)
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { filter, collection } = this.props;
    const { showPrice } = this.state;
    if (
      JSON.stringify(prevProps.filter) !== JSON.stringify(filter) ||
      JSON.stringify(prevProps.collection) !== JSON.stringify(collection)
    ) {
      return this.getData(filter, collection, showPrice)
        .then((data) =>
          this.setState({ data })
        );
    }
  }

  handleChangePrice = (value) => {
    const { data } = this.state;
    this.setState({
      showPrice: value,
      data: generateMarkerList(
        data.map((e) => e.inter),
        value
      ),
    });
  };

  setArrayFilter = (path, value) => {
    const field = (state) =>
      ({
        categorie: {
          name: 'selectedCat',
          filter: {
            categorie: { $in: state.selectedCat.map((e) => this.getCatId(e)) },
          },
          update: {
            selectedCat: state.selectedCat.toggleValue(value),
          },
        },
        'login.ajout': {
          name: 'selectedUsers',
          filter: { 'login.ajout': { $in: state.selectedUsers } },
          update: {
            selectedUsers: state.selectedUsers.toggleValue(value),
          },
        },
        allUsers: {
          name: 'allUsers',
          filter: value
            ? { 'login.ajout': { $exists: value } }
            : { 'login.ajout': { $in: state.selectedUsers } },
          update: {
            allUsers: value,
            selectedUsers: value ? state.users.map(e => e._id) : []
          },
        },
      }[path]);
    const { setData } = this.props;
    this.setState(
      field(this.state).update,
      () => setData(field(this.state).filter)
    );
  };

  render() {
    const {
      data,
      showFilters,
      selectedCat,
      showPrice,
      selectedUsers,
      allUsers,
      users,
    } = this.state;
    const { classes, hideFilter } = this.props;
    const height = 700;
    return (
      <div className={classes.main}>
        {hideFilter ? '' : <Grid
          container
          className={`${classes.button} ${
            !showFilters ? classes.hide : classes.display
          }`}
          alignItems="center"
        >
          {!showFilters ? (
            <Grid item>
              <Fab
                onClick={() => this.setState({ showFilters: !showFilters })}
                style={{ backgroundColor: 'white' }}
                size="medium"
                children={<KeyboardArrowRight />}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item xs={2}>
                  <Fab
                    onClick={() => this.setState({ showFilters: !showFilters })}
                    size="medium"
                    children={<KeyboardArrowLeft />}
                  />
                </Grid>
                <Grid item xs={10} className={classes.filter}>
                  <CateogriesFiltres
                    setArrayFilter={this.setArrayFilter}
                    choosenCats={selectedCat}
                  />
                  <ShowPrice
                    showPrice={showPrice}
                    handleChangePrice={this.handleChangePrice}
                  />
                  <SelectUser
                    users={users.toJS()}
                    allUsers={allUsers}
                    setArrayFilter={this.setArrayFilter}
                    selectedUsers={selectedUsers}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>}
        <Map height={`${height}px`} markerList={new List(data)} marker={{}} />
      </div>
    );
  }
}
export default connect(mapStateToProps)(withStyles(MapList, styles));
