import React from 'react';
import Iframe from 'react-iframe';
import classNames from 'classnames';

import { withStyles } from 'tss-react/mui';
import { Description } from '@mui/icons-material';

import {
  PDF_REGEX, IMAGE_REGEX
} from '@lba-dev/package.local-globals/regexps';

const styles = {
  container: {
    margin: 'auto',
    width: '100%',
    height: 200,
    position: 'relative',
  },
  centered: {
    width: '100%',
  },
  noPreview: {
    width: '100%',
    height: '100%',
    minHeight: 200,
    backgroundColor: 'white',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iframe: {
    borderColor: '#9d9d9d',
  },
  img: {
    width: '100%',
    height: 200,
    'object-fit': 'cover',
  },
  imgFull: {
    height: '100%',
    width: '100%',
    'object-fit': 'contain'
  },
};

const FileDisplayer = ({
  classes,
  content,
  fileOpenState,
  name = '',
  containerProps = {},
  type = (PDF_REGEX.test(name) && 'pdf') || (IMAGE_REGEX.test(name) && 'image')
}) =>
  <div className={classes.container}>
    {(type === 'pdf' &&
      <Iframe
        className={classNames(classes.centered, classes.iframe)}
        src={content}
        name={name}
        height="100%"
        width="80%"
        {...containerProps}
      />)
    || (type === 'image' &&
      <img
        className={classNames(classes.centered, fileOpenState ?
          classes.img : classes.imgFull )}
        src={content}
        alt={name}
        {...containerProps}
      />)
    || <div className={classes.noPreview}>
      <Description className={classes.centered} />
    </div>}
  </div>;

export default withStyles(FileDisplayer, styles);
