import moment from '@lba-dev/package.local-globals/moment';

export const rappelContent = ({
  number,
  road,
  zipCode,
  city,
  firstname,
  name,
  tel = 'Pas de numéro renseigné',
  email = 'Pas d\'adresse email renseignée',
  date,
  lien,
  commentaire,
  type,
  price = type === 'devis' ? 'Prix non renseigné' : '',
  description
}) => {

  const adresse = road ?
    `${number ? number : ''} ${road}, ${zipCode}, ${city}` : ''
  return `Adresse:
${adresse}

Nom: ${firstname} ${name}

Tel: ${tel}
Mail: ${email}

${!description ? '' : `Description:  ${description}`}
${price ? `Prix: ${price}` : ''}
Date de réalisation : ${moment(date).format('Do MMMM YYYY ')}
Lien: ${lien}

${!commentaire ? '' : `Commentaires: ${commentaire}`}
`}
