const metersToPixelsAtMaxZoom = (meters, latitude) =>
  meters / 0.075 / Math.cos((latitude * Math.PI) / 180);

export const layers = ({ index, city }) => ({
  id: `circles${index}`,
  source: 'markers',
  type: 'circle',
  paint: {
    'circle-radius': {
      stops: [
        [0, 0],
        [
          20,
          metersToPixelsAtMaxZoom(
            city.distance * 1000,
            city.location.lat
          ),
        ],
      ],
      base: 2,
    },
    'circle-opacity': 0.2,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000',
  },
  filter: ['==', 'modelId', index],
});


export const source = ({ index, city }) => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: [city.location.lng, city.location.lat],
  },
  properties: {
    modelId: index,
  },
});


export const map = ({ geojson }) => ({
  id: 'route',
  type: 'line',
  source: {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: geojson,
      },
    },
  },
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#3887be',
    'line-width': 5,
    'line-opacity': 0.75,
  },
});


export const eventCtrl = event => {
  if (
    event.originalEvent.ctrlKey ||
    event.originalEvent.metaKey ||
    event.originalEvent.altKey
  ) {
    return;
  }
  event.preventDefault();
};
